.commercial{
    cursor: pointer;
    &:hover{
        box-shadow: 4px 4px 10px rgb(165, 159, 159);
    }
}

.action-btn{
    border: none;
    background-color: transparent;
    transition: 250ms ease-in-out;

    &:hover{
        background-color: whitesmoke;
        border-radius: 10%;
    }
}