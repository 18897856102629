body{
    background-image: unset !important;
}

.SignIn>div{
    background-color: rgb(240 235 248) !important;
    height: 100vh !important;
    width: 100vw !important;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.br{
    border: 1px solid red !important;
}
.SignIn form{
    height: max-content !important;
}
.SignIn form input{
    outline: #212121 !important;
    height: 40px !important;
    
}
*:disabled {
    background-color: #ccc!important;
    border: 1px solid #999!important;
    color: #666!important;
}
.SignIn .fixed-bottom {
    display: none !important    ;
}

