// .sidebar-container{
//     z-index: 150 !important;
// }
body {
  --sideBarWidth: 210px;
}

.sidebar {
  z-index: 1 !important;
  position: fixed;
  line-height: 1.25rem;
  font-size: .975rem;
  letter-spacing: .0142857143em;
  font-weight: 400;
  color: #155f97;
  background-color: #f5f5f5;
  width: 0;
  padding: 0;
  overflow: hidden;
  transition: .2s ease-in;
}

.sidebar.open {
  width: var(--sideBarWidth);
}

.safeArea {
  width: calc(100vw - var(--sideBarWidth));
  margin-left: var(--sideBarWidth);
  transition: .2s ease-in;
}

.nav-item {
  margin-top: 10%;
}

.nav-link i{
  margin-left: 5px;
}

.nav-link {
  color: #3c4043;
  background-color: transparent;
}

.nav-pills .nav-link.active {
  color: #1967d2;
  background-color: #e8f0fe;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}


.btn {
  font-family: Google Sans, Roboto, Arial, sans-serif;
  font-size: .875rem;
  letter-spacing: .0107142857em;
  font-weight: 500;
  text-transform: none;
  transition: border .28s cubic-bezier(.4, 0, .2, 1), box-shadow .28s cubic-bezier(.4, 0, .2, 1);
  box-shadow: none;
}

.animatedSidebar {
  animation: ease-in-out;
  animation-name: leftRight;
  animation-duration: 500ms;
}

@keyframes leftRight {
  0% {
    left: -10%;
  }

  50% {
    left: -5%;
  }

  100% {
    left: 0%;
  }
}