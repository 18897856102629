
@page{
    size: A4;
    margin: 0;
}
.Billing{
    width: 210mm !important;
    height: 297mm !important;
    .w-100 {
        width: 210mm!important;
    }
    .border-3 {
        border-width: 3px!important;
    }
    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
    }
    .position-relative {
        position: relative!important;
    }
    .fixed-bottom {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
    }
    .rounded {
        border-radius: .25rem!important;
    }
    .m-2 {
        margin: .5rem!important;
    }
    
    .border {
        border: 1px solid #dee2e6!important;
    }
    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
    }
    .col-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .m-2 {
        margin: .5rem!important;
    }
    .col-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .w-50 {
        width: 50%!important;
    }
    .img-fluid {
        max-width: 100%;
        height: auto;
    }
    img, svg {
        vertical-align: middle;
    }
    .flex-column {
        flex-direction: column!important;
    }
    .d-flex {
        display: flex!important;
    }
    .h2, h2 {
        font-size: 2rem;
    }
    h1, h2, h3, h4, h5, h6, .font-primary {
        font-family: "Raleway", sans-serif;
    }
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
    }
    h2 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .fw-bold {
        font-weight: 700!important;
    }
    .mt-3 {
        margin-top: 1rem!important;
    }
    .justify-content-around {
        justify-content: space-around!important;
    }
    .col-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table {
        --bs-table-bg: transparent;
        --bs-table-accent-bg: transparent;
        --bs-table-striped-color: #212529;
        --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
        --bs-table-active-color: #212529;
        --bs-table-active-bg: rgba(0, 0, 0, 0.1);
        --bs-table-hover-color: #212529;
        --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
        width: 100%;
        margin-bottom: 1rem;
        color: #212529;
        vertical-align: top;
        border-color: #dee2e6;
    }
    table {
        caption-side: bottom;
        border-collapse: collapse;
    }
    table {
        display: table;
        border-collapse: separate;
        box-sizing: border-box;
        text-indent: initial;
        border-spacing: 2px;
        border-color: gray;
    }
    .table>thead {
        vertical-align: bottom;
    }
    tbody, td, tfoot, th, thead, tr {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
    }
    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }
    .justify-content-around {
        justify-content: space-around!important;
    }
    .m-4 {
        margin: 1.5rem!important;
    }
    .mb-2 {
        margin-bottom: .5rem!important;
    }
    .mt-2 {
        margin-top: .5rem!important;
    }
    .text-center {
        text-align: center!important;
    }
    .m-2 {
        margin: .5rem!important;
    }
    .border-bottom {
        border-bottom: 1px solid #dee2e6!important;
    }
    .col-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .flex-row {
        flex-direction: row!important;
    }
    .rounded {
        border-radius: .25rem!important;
    }
    .m-4 {
        margin: 1.5rem!important;
    }
    .col-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .fs-4 {
        font-size: 1.5rem!important;
    }
    .text-decoration-underline {
        text-decoration: underline!important;
    }
    .d-flex {
        display: flex!important;
    }
    .text-center {
        text-align: center!important;
    }
    .col-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .mt-5 {
        margin-top: 3rem!important;
    }
    .gap-5 {
        gap: 3rem!important;
    }
    .col-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .fs-3 {
        font-size: 1.75rem!important;
    }
    .col-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }    
    .col-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .flex-column {
        flex-direction: column!important;
    }
    .d-flex {
        display: flex!important;
    }

.justify-content-center {
    justify-content: center!important;
}
.bg-primary {
    --bs-bg-opacity: 1;
    background-color: #0d6efd!important;
}
.text-white{
    color: white;
}
.bg-light{
    background-color: #f8f9fa;
}
.text-light{
    color: #f8f9fa;
}
.w-100 {
    width: 100% !important;
}
.border-3 {
    border-width: 3px!important;
}
} 
