.Message{
    z-index: 8700000;
    position: fixed;
    float: left;
    bottom: 0;
    right: 0;
    margin-top: 1%;
    margin-right: 25px;
    border-radius: 10px;
}

.Message__close{
    cursor: pointer;
    position: absolute;
    left: 95%;
    top: 0;
    transition: .2s ease-in-out;
}

.Message__close:hover{
    scale: 1.2;
}