@import url('https://fonts.cdnfonts.com/css/poppins');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dcdfdc;
  font-size: 1em !important;
  overflow-x: hidden;
}

.PageInfo{
  background-color: white;
}

input, select, button{
  height: 45px;
}

