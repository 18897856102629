.form-icon-container{
    background-color: transparent;
    position: absolute;
    margin-top: -5%;
    width: max-content;
    border-radius: 50%;


    i{
        
    }
}