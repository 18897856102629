.Loader{
    z-index: 400000 !important;
    position:  fixed;
    top: 0;
    bottom: 0;
    left:0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.3);
    width: 100vw;
    height: 100vh;
    text-align: center;
    

    .Spinner{
        z-index: 400000 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        margin: auto;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        border: 10px solid rgb(250, 246, 246);
        border-top: 10px solid #0d6efd;
        border-bottom: 10px solid #0d6efd;
        animation: spin 2s linear infinite;
    }

    .spinner {
        z-index: 400000 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 56px;
        height: 56px;
        display: grid;
        border-radius: 50%;
        -webkit-mask: radial-gradient(farthest-side,#0000 40%,#0d6efd 41%);
        background: linear-gradient(0deg ,rgba(13,110,253,0.5) 50%,rgba(13,110,253,1) 0) center/4.5px 100%,
             linear-gradient(90deg,rgba(13,110,253,0.25) 50%,rgba(13,110,253,0.75) 0) center/100% 4.5px;
        background-repeat: no-repeat;
        animation: spinner-d3o0rx 1s infinite steps(12);
     }
     
     .spinner::before,
     .spinner::after {
        content: "";
        grid-area: 1/1;
        border-radius: 50%;
        background: inherit;
        opacity: 0.915;
        transform: rotate(30deg);
     }
     
     .spinner::after {
        opacity: 0.83;
        transform: rotate(60deg);
     }
     
     @keyframes spinner-d3o0rx {
        100% {
           transform: rotate(1turn);
        }
     }
}

@keyframes spin {
    0%{transform: rotate(0deg);}
    100%{transform: rotate(360deg);}
}