#header{
    background-color: #383E42 !important;
    z-index: 30 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}


.toggleBtn{
    cursor: pointer;
}
.toggleBtnContent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    background-color: #ffffff08;
    transition: .2s ease-in-out;
}
.toggleBtn:hover .toggleBtnContent{
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background-color: #ffffff50;

}

.nav-item{
    min-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}
.nav-item .nav-link{
    min-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    gap: 2em;
}

.nav-link>span{
    flex:1;
    /* border: 1px solid red; */
    text-align: left;
}