
#accounting{
    border: 1px solid #dee2e6!important;
    padding: 1.5rem!important;
    .p-4 {
        padding: 1.5rem!important;
    }
    .m-4 {
        margin: 1.5rem!important;
    }
    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
    }
    *, ::after, ::before {
        box-sizing: border-box;
    }
    .mt-3 {
        margin-top: 1rem!important;
    }
    .table {
        --bs-table-bg: transparent;
        --bs-table-accent-bg: transparent;
        --bs-table-striped-color: #212529;
        --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
        --bs-table-active-color: #212529;
        --bs-table-active-bg: rgba(0, 0, 0, 0.1);
        --bs-table-hover-color: #212529;
        --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
        width: 100%;
        margin-bottom: 1rem;
        color: #212529;
        vertical-align: top;
        border-color: #dee2e6;
    }
    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y);
    }
    table {
        caption-side: bottom;
        border-collapse: collapse;
    }
    table {
        display: table;
        border-collapse: separate;
        box-sizing: border-box;
        text-indent: initial;
        border-spacing: 2px;
        border-color: gray;
    }
    .rounded-circle {
        border-radius: 50%!important;
    }
    img, svg {
        vertical-align: middle;
    }
    .col-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }
    .fw-bolder {
        font-weight: bolder!important;
    }
    .col-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .text-uppercase {
        text-transform: uppercase!important;
    }
    .col-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .mt-3 {
        margin-top: 1rem!important;
    }
    *, ::after, ::before {
        box-sizing: border-box;
    }
    .col-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .border {
        border: 1px solid #dee2e6!important;
    }
    .border-bottom {
        border-bottom: 1px solid #dee2e6!important;
    }
    .d-flex {
        display: flex!important;
    }
    .col-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    h1, h2, h3, h4, h5, h6, .font-primary {
        font-family: "Raleway", sans-serif;
    }
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
    }
    *, ::after, ::before {
        box-sizing: border-box;
    }
    







}
