.Modal{
    position: fixed;
    z-index: 1800 !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);

    .modal-content{
        .modal-header{

            .close{
                border: none !important;
            }

        }
        .modal-body{

        }
        .modal-footer{

        }
    }

}